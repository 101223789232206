import React, { useEffect } from 'react';
import { useLoaderData } from '@remix-run/react';

import Button from '~/components/Button';
import Icon from '~/components/Icon';
import { useAuth } from '~/contexts/AuthContext';
import { useEnv } from '~/hooks/useEnv';

import type { ENV } from '~/types';

type Provider = 'google' | 'twitch' | 'discord';

const OAUTH_URLS: Record<Provider, keyof ENV> = {
  google: 'GOOGLE_OAUTH_URL',
  twitch: 'TWITCH_OAUTH_URL',
  discord: 'DISCORD_OAUTH_URL'
} as const;

export default function Login() {
  const env = useLoaderData<ENV>();
  useEnv(env);
  const { checkAuth } = useAuth();

  const handleLogin = (provider: Provider) => (e: React.MouseEvent) => {
    e.preventDefault();
    const width = 600;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    const oauthUrl = env[OAUTH_URLS[provider]];
    const popup = window.open(
      oauthUrl,
      `Login with ${provider.charAt(0).toUpperCase() + provider.slice(1)}`,
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (!popup) {
      window.location.href = oauthUrl;
      return;
    }

    popup.focus();
  };

  useEffect(() => {
    const handleAuthMessage = async (event: MessageEvent) => {
      if (
        event.origin === window.location.origin &&
        event.data?.type === 'auth-success'
      ) {
        await checkAuth();
      }
    };

    window.addEventListener('message', handleAuthMessage);
    return () => window.removeEventListener('message', handleAuthMessage);
  }, [checkAuth]);

  return (
    <div className="flex grow flex-col justify-end">
      <h2 className="h-2xl sm:h-3xl mb-8">Sign in</h2>
      <div className="mb-10 flex gap-2.5 gap-x-4 max-xs:flex-col">
        <Button
          className="w-full bg-[#af3328] text-center after:bg-brand-google"
          onClick={handleLogin('google')}
        >
          <span>Google</span>
        </Button>
        <Button
          className="w-full bg-[#3f49b4] text-center after:bg-brand-discord"
          onClick={handleLogin('discord')}
        >
          <span>Discord</span>
        </Button>
        <Button
          className="w-full bg-[#6a32be] text-center after:bg-brand-twitch"
          onClick={handleLogin('twitch')}
        >
          <span>Twitch</span>
        </Button>
      </div>
      <ul className="space-y-1.5 *:flex *:gap-2.5">
        <li>
          <Icon name="star" className="shrink-0 text-yellow-500" />
          <span className="p-base">Track your scores</span>
        </li>
        <li>
          <Icon name="star" className="shrink-0 text-yellow-500" />
          <span className="p-base">Compete on the leaderboard</span>
        </li>
        <li>
          <Icon name="star" className="shrink-0 text-yellow-500" />
          <span className="p-base">
            Keep your progress across multiple devices
          </span>
        </li>
      </ul>
    </div>
  );
}
